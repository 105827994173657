import axios from 'axios';
import store from './store';
import Cookies from "js-cookie";

export const freshState = async (state = null) => {
    if(state) {
        await store.dispatch('updateFreshState', state);
        return;
    }
    if(!Cookies.get('token')) {
        return;
    }

    try {
        const response = await axios.get('https://mobile-foodriver.site/staging/api/v1/user/airdrop/conditions', {
            headers: {
                'Authorization': 'Bearer ' + window.token,
            }
        });
        await store.dispatch('updateFreshState', response.data);
    } catch (error) {
        console.error('Error fetching fresh state:', error);
    }
};
