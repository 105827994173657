// main.js
import 'maz-ui/styles' // or import 'maz-ui/css/main.css'

import "../public/global.css";
import "../public/styleguide.css";
import { createApp } from 'vue';
import Desktop from './screens/Desktop.vue';
import { freshState } from './freshState';
import store from './store';

import 'flag-icons/css/flag-icons.min.css';
import 'v-phone-input/dist/v-phone-input.css';
import { createVPhoneInput } from 'v-phone-input';

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import {VAutocomplete} from "vuetify/components";
import DoneButton from "./components/DoneButton.vue";

const vuetify = createVuetify({
    components,
    directives,
})

const Vue = createApp(Desktop);
Vue.use(store);
Vue.use(vuetify)
const vPhoneInput = createVPhoneInput({
    countryIconMode: 'svg',
    persistentHint: true,
    countryLabel: 'Country (code)'

});



Vue.component('VAutocomplete', VAutocomplete);
Vue.component('DoneButton', DoneButton);
Vue.use(vPhoneInput);

Vue.mount('#app');

freshState();
