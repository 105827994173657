<template>
    <div class="bg-gray-200 flex flex-row justify-center w-full desktop">
        <div class=" w-full" style="background-color: #F8F8F8FF;">
            <div>
                <div class="flex justify-center pt-12">
                  <MainLogo class="foodlogo z-10" />
                </div>
                <img class="absolute top-0 object-cover w-full h-full background" style="z-index: 0" alt="Element" src="/img/background.jpg"/>
                <div class="flex justify-center">
                    <div class="flex flex-col items-center mt-20 z-10 p-2 text-center">
                        <p class=" font-bold text-4xl">
                             <span class="text-red-600">Join the
                           FooDriver</span>
                            <span style="color: #2B2F50;"> Coin</span>
                        </p>
                        <p class=" font-bold text-4xl">
                            <span style="color: #2B2F50;"> Airdrop and Get Rewarded</span>
                            <span class="text-red-600"> 7 FDC</span>
                        </p>
                    </div>
                </div>
                <div class="mt-12 flex justify-center text-4xl" style="color: #2B2F50">Conditions:</div>


                <div class="flex flex-wrap justify-center p-10 font-bold blue text-2xl">


                    <div class="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg text w-64 relative h-64 m-2">
                        <div class="big-digit">1</div>
                        <div class="text-center mb-12">
                            <span class="text-red-600 font-bold">Download</span> <span class="blue">and register in the App</span>
                        </div>
                        <DownloadApp  />
                    </div>


                    <div class="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg text w-64 relative h-64 m-2">
                        <div class="big-digit">2</div>
                        <div class="text-center mb-12">
                            <span class="text-red-600 font-bold">Subscribe</span> <span class="blue">and post a Tweet</span>
                        </div>
                        <Twitter />
                    </div>


                    <div class="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg text w-64 relative h-64 m-2">
                        <div class="big-digit">3</div>
                        <div class="text-center mb-12">
                            <span class="text-red-600 font-bold">Follow</span> <span class="blue">the Telegram Channel</span>
                        </div>
                        <Telegram />
                    </div>


                    <div class="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg text w-64 relative h-64 m-2">
                        <div class="big-digit">4</div>
                        <div class="text-center mb-8">
                            <span class="text-red-600 font-bold">Enter</span> <span class="blue">your crypto wallet</span>
                        </div>
                        <div class="text-center mb-8 text-gray-500 text-sm">
                            We will send you to this crypto wallet*
                        </div>
                        <SubmitCryptoAddress />
                    </div>

                </div>


                <div class="flex justify-center text-3xl mt-8 text-center blue">
                    <div class="">
                        <span class="font-bold">Participants </span>
                        <span class="">who have already<br/>fulfilled the conditions:</span>
                        <br>

                    </div>

                </div>
                <div class="flex justify-center mt-8 text-center blue">
                    <p class="text-5xl text-bold font-bold" id="total_registered_count"></p>
                </div>

                <!--                Монетки -->
              <img class="coin-1" style="" alt="Image" src="/img/coin1.png"/>

                <img class="coin-2" alt="Image" src="/img/coin2.png"/>



                <footer class=" mt-12 ">
                    <div class="sm:h-[150px] h-[200px]  relative w-full flex justify-around flex-wrap pt-4 md:pt-12"
                         style="background: linear-gradient(180deg, rgb(43, 47, 80) 0%, rgb(24.65, 27.5, 51) 100%)">


                        <div class="mb-4 w-full sm:w-fit">
                            <div class="flex justify-center">
                                <footer-logo />

                            </div>
                            <div class="footer-text text-center mt-4">All rights reserved © 2024 FDrive Inc. </div>
                            <div class="flex justify-center mt-2">
                                <div class="mr-2">
                                    <a href="https://x.com/FooDriver_dApp" target="_blank">
                                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.5 0C5.87281 0 0.5 5.37281 0.5 12C0.5 18.6272 5.87281 24 12.5 24C19.1272 24 24.5 18.6272 24.5 12C24.5 5.37281 19.1272 0 12.5 0Z" fill="white"/>
                                            <path d="M13.813 10.9128L18.95 4.94141H17.7327L13.2722 10.1263L9.70961 4.94141H5.60059L10.9879 12.7819L5.60059 19.0438H6.81797L11.5284 13.5684L15.2907 19.0438H19.3998L13.8127 10.9128H13.813ZM7.25661 5.85785H9.12643L17.7333 18.1691H15.8634L7.25661 5.85785Z" fill="#20233E"/>
                                        </svg>
                                    </a>
                                </div>
                                <a target="_blank" href="https://t.me/FooDriverTestBot">
                                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 0C5.87281 0 0.5 5.37281 0.5 12C0.5 18.6272 5.87281 24 12.5 24C19.1272 24 24.5 18.6272 24.5 12C24.5 5.37281 19.1272 0 12.5 0ZM18.0622 8.16094C17.8817 10.0584 17.1003 14.663 16.7028 16.7883C16.5345 17.6878 16.2031 17.9892 15.8825 18.0187C15.1859 18.083 14.6567 17.558 13.9817 17.1155C12.9256 16.4231 12.3289 15.9923 11.3038 15.3169C10.1187 14.5359 10.887 14.107 11.562 13.4058C11.7388 13.222 14.8091 10.4292 14.8686 10.1761C14.8761 10.1442 14.8831 10.0261 14.8128 9.96375C14.7425 9.90141 14.6389 9.92297 14.5644 9.93984C14.4584 9.96375 12.7709 11.0789 9.50281 13.2853C9.02375 13.6144 8.59016 13.7742 8.20109 13.7662C7.77266 13.7569 6.94859 13.5239 6.33594 13.3247C5.58453 13.0805 4.98734 12.9511 5.03937 12.5363C5.06609 12.3202 5.36375 12.0994 5.93187 11.8734C9.43016 10.3491 11.7627 9.34453 12.9298 8.85891C16.2626 7.47286 16.955 7.23187 17.4064 7.22391C17.9718 7.21392 18.1082 7.68061 18.0622 8.16094Z" fill="white"/>
                                    </svg>
                                </a>


                            </div>
                        </div>

                        <div class="md:mt-4">
                            <div class="footer-text">
                                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.foodriver.general&pcampaignid=web_share">Google Play</a>
                                <div class=" mt-4"> <a target="_blank" href="https://apps.apple.com/app/foodriver-food-and-drinks/id6474551114">Apple Store</a></div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>

import 'flowbite'
import DownloadApp from "../components/DownloadApp.vue";
import Telegram from "../components/Telegram.vue";
import axios from "axios";
import Cookies from "js-cookie";
import Twitter from "../components/Twitter.vue";
import SubmitCryptoAddress from "../components/SubmitCryptoAddress.vue";
import {mapGetters} from "vuex";
import MainLogo from "../components/SVG/MainLogo.vue";
import FooterLogo from "../components/SVG/FooterLogo.vue";

export default {
    emits: ['freshState'],
    data() {
        return {
            modal: ''
        }
    },
    components: {
        FooterLogo,
        DownloadApp, Telegram, Twitter, SubmitCryptoAddress, MainLogo
    },

    name: "Desktop",
    computed: {
        ...mapGetters(['getFreshState']),
    },
    methods: {

    },
    async mounted() {
        window.token = Cookies.get('token')

        try {
            const response = await axios.get('https://mobile-foodriver.site/staging/api/v1/user/airdrop/conditions_completed_count');
            document.getElementById('total_registered_count').innerHTML = response.data
        } catch (error) {
            console.error(error)
        }
    }
};
</script>

<style>
.background {
  opacity: 0.06;
}




.big-digit {
    position: absolute;
    opacity: 0.1;
    top: 5px;
    font-family: Inter;
    font-size: 160px;
    font-weight: 700;
    line-height: 106px;
    right: 12px;
}


.coin-2 {
    height: 336px;
    right: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 396px;
}

.coin-1 {
    height: 372px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 276px;
}

.footer-text {
    color: var(--white-base);
    font-family: "Inter", Helvetica;
    font-size: 14px;
    font-weight: 400;
    left: 135px;
    letter-spacing: -0.56px;
    line-height: 14.2px;
    opacity: 0.6;

}
.tooltip {

}
.tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: white;
    color: black;
    text-align: center;
    border-radius: 6px;
    padding: 20px 10px;
    border: 1px solid black; /* Добавляем рамку */
    position: absolute;
    z-index: 1;
    bottom: 150%; /* Расположите всплывающее окно выше */
    left: 50%;
    margin-left: -100px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}


</style>