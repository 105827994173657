<template>
    <button
        v-if="!this.isTelegramChatSubscribed"
        @click="toggleTelegramModal"
        :class="buttonClass"
        class="tooltip text-white py-4 px-6 rounded text-xl mt-auto w-[90%] absolute bottom-4"
    >
        Follow
        <span v-if="!this.getFreshState.token" class="tooltiptext blue font-bold"><span
            class="text-red-600">Unavailable</span><span class="blue">: Please finish previous steps</span></span>
    </button>
    <DoneButton v-else />

    <div id="modal_telegram" tabindex="-1" aria-hidden="true"
         class="p-2 hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
    >
        <div class="relative p-4 w-full max-w-xl max-h-full p-2">
            <div class="bg-white rounded-lg p-6 w-full max-w-xl">
                <button class="absolute top-8 right-8 text-gray-500 hover:text-gray-800" @click="toggleTelegramModal">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                         class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
                    </svg>
                </button>
                <div class="text-center blue">
                    <h2 class="text-2xl font-semibold mb-4">Step 1</h2>
                    <h3 class="text-2xl font-bold  mb-4">
                        <span class="text-red-600">Follow</span>
                        <span class="blue"> the Telegram channel</span>
                    </h3>
                    <button  class="bg-red-600 text-white rounded py-2 px-4 hover:bg-red-700 verify_button w-[70%] mb-2"  >
                        <a :href="'https://t.me/' + getFreshState.telegramFooDriverChatId"
                           target="_blank">Follow</a>
                    </button>
                    <h2 class="text-2xl font-semibold mb-2">Step 2</h2>
                    <div class="flex justify-center">
                        <h3 class="text-2xl font-bold  mb-4 w-[80%]">Verify following by getting verification code from
                            <span
                                :href="'https://t.me/' + getFreshState.telegramFooDriverBotUserName"
                                class=" text-red-600"
                                target="_blank">bot</span>
                        </h3>

                    </div>
                    <button  class="bg-red-600 text-white rounded py-2 px-4 hover:bg-red-700 verify_button w-[70%] mb-4"  >
                        <a  :href="'https://t.me/' + getFreshState.telegramFooDriverBotUserName"
                            target="_blank">Get the code</a>
                    </button>
                        <input type="text" v-model="code" placeholder="Enter verification code from bot"
                               class="border border-gray-300 rounded p-2 mb-4 w-[70%]">
                        <button @click="verify" class="bg-red-600 text-white rounded py-2 px-4 hover:bg-red-700 verify_button w-[70%]"  >Verify
                        </button>

                    <div v-if="message" :class="{'text-red-600': isError, 'text-green-600': !isError}" class="mt-4">{{
                            message
                        }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import {mapActions, mapGetters} from 'vuex';
import {Modal} from "flowbite";
import Cookies from 'js-cookie';

export default {
    name: 'ModalComponent',
    data() {
        return {
            telegramModal: '',
            code: '',
            message: '',
            isError: false,
        };
    },
    computed: {
        ...mapGetters(['getFreshState']),
        isTelegramChatSubscribed() {
            return this.getFreshState.isTelegramChatSubscribed;
        },
        buttonClass() {
            if (this.isTelegramChatSubscribed) {
                return 'bg-green-600';
            }

            if (!this.getFreshState.token) {
                return 'bg-gray-500 cursor-not-allowed';
            } else {
                return 'bg-red-600';
            }
        }
    },
    methods: {
        ...mapActions(['updateFreshState']),
        toggleTelegramModal() {
            if (this.getFreshState.token) {
                this.telegramModal.toggle();
            }
        },
        async verify() {
            try {
                await axios.get('https://mobile-foodriver.site/staging/api/v1/user/telegram/verify?verificationCode=' + this.code, {
                    headers: {
                        'Authorization': 'Bearer ' + this.getFreshState.token,
                    }
                });
                const response = await axios.post('https://mobile-foodriver.site/staging/api/v1/user/airdrop/evaluate_telegram_chat_subscribed_condition', {}, {
                    headers: {
                        'Authorization': 'Bearer ' + this.getFreshState.token,
                    }
                });
                await this.updateFreshState(response.data);
                if (this.getFreshState.isTelegramChatSubscribed) {
                    this.isError = false;
                    this.toggleTelegramModal();
                } else {
                    this.message = "Failed to verify. Please try again.";
                    this.isError = true;
                }

            } catch (error) {
                this.message = error.response.data.errorMessage || "Failed to verify. Please try again.";
                this.isError = true;
            }
        }
    },
    mounted() {
        const targetEl = document.getElementById('modal_telegram');
        const options = {
            placement: 'center',
            backdropClasses: 'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40',
        };
        this.telegramModal = new Modal(targetEl, options);
    }
};
</script>

<style scoped>
.verify_button {

    height: 48px;
    padding: 12px 25px 12px 25px;
    gap: 15px;
    border-radius: 4px ;


}
</style>
