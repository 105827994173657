<template>
    <button
        v-if="!getFreshState.walletAddress"
        @click="toggleWalletModal"
        :class="buttonClass"
        class="tooltip text-white py-4 px-6 rounded text-xl mt-auto w-[90%] absolute bottom-4"
    >
        Enter
        <span v-if="!this.getFreshState.isTelegramChatSubscribed || !this.getFreshState.isTwitterFollowing || !this.getFreshState.isTwitterAnyRetweet"
              class="tooltiptext blue font-bold"><span class="text-red-600">Unavailable</span>: Please finish previous steps</span>
    </button>

    <DoneButton v-else />

    <div id="modal_wallet" tabindex="-1" aria-hidden="true"
         class="p-2 hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
    >
        <div class="relative p-4 w-full max-w-xl max-h-full p-2">
            <div class="bg-white rounded-lg p-6 w-full max-w-xl relative">
                <button class="absolute top-4 right-4 text-gray-500 hover:text-gray-800" @click="toggleWalletModal">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
                    </svg>
                </button>
                <div class="text-center blue">
                    <h2 class="text-lg font-semibold mb-1">Step 5</h2>
                    <h3 class="text-3xl font-bold mb-2">
                        <span class="text-red-600">Enter</span> your crypto wallet
                    </h3>
                    <p class="mb-4 text-sm font-normal font-weight-bold  text-gray-500">We will send you to this crypto wallet*</p>
                    <input type="text" v-model="walletAddress" placeholder="Enter your crypto wallet" class="border border-gray-300 rounded p-2 mb-4 w-full">
                    <button @click="submitWalletAddress" class="bg-red-600 text-white rounded py-2 px-4 w-full hover:bg-red-700">Submit</button>
                    <div v-if="message" :class="{'text-red-600': isError, 'text-green-600': !isError}" class="mt-4">{{ message }}</div>
                </div>
            </div>
        </div>
    </div>

    <!-- Уведомление -->
    <div id="notification" class="notification hidden">
        Registration is completed!
    </div>

    <!-- Конфетти -->
    <div id="confetti-container"></div>
</template>

<script>
import axios from 'axios';
import {mapActions, mapGetters} from 'vuex';
import {Modal} from "flowbite";
import Cookies from 'js-cookie';

export default {
    name: 'SubmitCryptoAddress',
    data() {
        return {
            walletModal: '',
            walletAddress: '',
            message: '',
            isError: false,
        };
    },
    computed: {
        ...mapGetters(['getFreshState']),
        canOpenModal() {
            const state = this.getFreshState;
            return state.isTelegramAccountVerified && state.isTelegramChatSubscribed &&
                state.isTwitterAnyRetweet && state.isTwitterFollowing && !state.walletAddress;
        },
        buttonClass() {
            if (this.getFreshState.walletAddress) {
                return 'bg-green-600';
            }

            if (this.canOpenModal) {
                return 'bg-red-600';
            } else {
                return 'bg-gray-500 cursor-not-allowed';
            }
        }
    },
    methods: {
        ...mapActions(['updateFreshState']),
        toggleWalletModal() {
            if (this.canOpenModal) {
                this.walletModal.toggle();
            }
        },
        async submitWalletAddress() {
            try {
                const response = await axios.post('https://mobile-foodriver.site/staging/api/v1/user/airdrop/wallet', {
                    walletAddress: this.walletAddress
                }, {
                    headers: {
                        'Authorization': 'Bearer ' + this.getFreshState.token,
                    }
                });

                await this.updateFreshState(response.data);
                this.isError = false;
                this.walletModal.toggle();
                this.createConfetti();
                this.showNotification();
            } catch (error) {
              this.message = error.response.data.errorMessage || "Failed to verify. Please try again.";
                this.isError = true;
            }
        },
        showNotification() {
            const notification = document.getElementById('notification');
            notification.classList.remove('hidden');
            notification.classList.add('visible');

            setTimeout(() => {
                notification.classList.remove('visible');
                notification.classList.add('hidden');
            }, 5000);
        },
        createConfetti() {
            const confettiContainer = document.getElementById('confetti-container');
            for (let i = 0; i < 200; i++) { // Увеличено количество конфетти
                const confetti = document.createElement('div');
                confetti.classList.add('confetti');
                confetti.style.left = `${Math.random() * 100}%`;
                confetti.style.backgroundColor = `hsl(${Math.random() * 360}, 100%, 50%)`;
                confetti.style.animationDuration = `${Math.random() * 5 + 3}s`;
                confetti.style.transform = `rotate(${Math.random() * 360}deg)`;
                confettiContainer.appendChild(confetti);
            }

            setTimeout(() => {
                while (confettiContainer.firstChild) {
                    confettiContainer.removeChild(confettiContainer.firstChild);
                }
            }, 10000);
        }
    },
    mounted() {

        this.getFreshState.token = Cookies.get('token');
        const targetEl = document.getElementById('modal_wallet');
        const options = {
            placement: 'center',
            backdropClasses: 'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40',
        };
        this.walletModal = new Modal(targetEl, options);
    }
};
</script>

<style >
.notification {
    position: fixed;
    top: -100px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgb(5 122 85 / var(--tw-bg-opacity));
    color: white;
    padding: 40px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: top 0.5s ease;
    z-index: 1000;
}

.notification.hidden {
    top: -100px;
}

.notification.visible {
    top: 20px;
}

#confetti-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 999;
}

.confetti {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: red;
    opacity: 0;
    animation: fall 4s linear infinite;
}

@keyframes fall {
    0% {
        opacity: 1;
        transform: translateY(-100vh) rotate(0deg);
    }
    100% {
        opacity: 0;
        transform: translateY(100vh) rotate(720deg);
    }
}

</style>
