<template>
    <button
        v-if="!isTwitterFollowing"
        @click="toggleTwitterModal"
        :class="buttonClass"
        class="tooltip text-white py-4 px-6 rounded text-xl mt-auto w-[90%] absolute bottom-4"
    >
        Subscribe
        <span v-if="!this.getFreshState.token" class="tooltiptext blue font-bold"><span class="text-red-600">Unavailable</span>: Please finish previous steps</span>

    </button>

    <DoneButton v-else />


    <div id="modal_twitter" tabindex="-1" aria-hidden="true"
         class="p-2 hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
    >
        <div class="relative p-4 w-full max-w-xl max-h-full p-2">
            <div class="bg-white rounded-lg p-6 w-full max-w-xl ">
                <button class="absolute top-8 right-8 text-gray-500 hover:text-gray-800" @click="toggleTwitterModal">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
                    </svg>
                </button>
                <div class="text-center blue">
                    <h2 class="text-2xl font-semibold mb-4">Step 1</h2>
                    <h3 class="text-2xl font-bold  mb-4">
                        <span class="text-red-600">Subscribe</span> and post a Tweet
                    </h3>
                    <a :href="'https://x.com/' + getFreshState.twitterFooDriverUserName"
                       target="_blank">
                        <button  class="bg-red-600 text-white rounded py-2 px-4 hover:bg-red-700 verify_button w-[70%] mb-4">
                           Subscribe
                        </button>
                    </a>
                    <h2 class="text-2xl font-semibold mb-4">Step 2</h2>
                    <h3 class="text-2xl font-bold mb-4">
                        <span class="text-red-600">Link</span> account to check
                    </h3>
                    <a :href="twitterOAuthLink"
                       target="_blank">
                        <button  class="bg-red-600 text-white rounded py-2 px-4 hover:bg-red-700 verify_button w-[70%] mb-4">
                           Link
                        </button>
                    </a>
                    <h2 class="text-2xl font-semibold mb-4">Step 3</h2>
                    <h3 class="text-2xl font-bold mb-4">
                        <span class="text-red-600">Verify</span> subscription and retweet
                    </h3>
                    <button @click="verify" class="bg-red-600 text-white rounded py-2 px-4 hover:bg-red-700 verify_button w-[70%]">Verify</button>
                    <div v-if="message" :class="{'text-red-600': isError, 'text-green-600': !isError}" class="mt-4">{{ message }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import { Modal } from "flowbite";
import Cookies from 'js-cookie';
import {freshState} from "../freshState";

export default {
    name: 'TwitterModalComponent',
    data() {
        return {
            twitterModal: '',
            code: '',
            message: '',
            isError: false,
            twitterOAuthLink: ''
        };
    },
    computed: {
        ...mapGetters(['getFreshState']),
        isTwitterFollowing() {
            return this.getFreshState.isTwitterFollowing && this.getFreshState.isTwitterAnyRetweet;
        },
        buttonClass() {
            if (this.isTwitterFollowing) {
                return 'bg-green-600';
            }

            if (!this.getFreshState.token) {
                return 'bg-gray-500 cursor-not-allowed';
            } else {
                return 'bg-red-600';
            }
        }
    },
    methods: {
        ...mapActions(['updateFreshState']),
        toggleTwitterModal() {
            if(!this.getFreshState.token) {
                return;
            }
            this.twitterModal.toggle();
        },
        async verify() {
            try {
                await axios.post('https://mobile-foodriver.site/staging/api/v1/user/airdrop/evaluate_twitter_following_condition',  {},{
                    headers: {
                        'Authorization': 'Bearer ' + this.getFreshState.token,
                    }
                });
                const response = await axios.post('https://mobile-foodriver.site/staging/api/v1/user/airdrop/evaluate_twitter_any_retweet_condition', {},{
                    headers: {
                        'Authorization': 'Bearer ' + this.getFreshState.token,
                    }
                });
                await freshState(response.data)
                if(!this.isTwitterFollowing) {
                    this.message = "You did not followed or not posted any tweet. Check again.";
                    this.isError = true;
                    return
                }
                this.isError = false;
                this.toggleTwitterModal();
            } catch (error) {
                this.message = "Failed to verify. Please try again.";
                this.isError = true;
            }
        }
    },
    async mounted() {
        this.getFreshState.token = Cookies.get('token');
        const targetEl = document.getElementById('modal_twitter');
        const options = {
            placement: 'center',
            backdropClasses: 'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40',
        };
        this.twitterModal = new Modal(targetEl, options);


        if(!this.getFreshState.token) {
            return;
        }
        try {
            const response = await axios.get('https://mobile-foodriver.site/staging/api/v1/user/twitter/oauth/begin?redirectUrl=' + encodeURIComponent(window.location.href), {
                headers: {
                    'Authorization': 'Bearer ' + this.getFreshState.token,
                }
            });
            this.twitterOAuthLink = response.data;
        } catch (error) {
            console.error('Failed to fetch Twitter OAuth link', error);
        }


        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('twitterSuccess') === 'true') {
            window.history.replaceState(null, '', window.location.pathname);
            this.twitterModal.show();
            return;

        }
        if(urlParams.get('twitterAccountAlreadyUsed') === 'true') {
            window.history.replaceState(null, '', window.location.pathname);
            this.twitterModal.show();
            this.message = "Twitter account is already in use";
            this.isError = true;
            return;
        }
        if (urlParams.get('twitterError') === 'true') {
            window.history.replaceState(null, '', window.location.pathname);
            this.twitterModal.show();
            this.message = "Failed to link twitter account. Please try again.";
            this.isError = true;
        }

    }
};
</script>

<style scoped>

.verify_button {

    height: 48px;
    padding: 12px 25px 12px 25px;
    gap: 15px;
    border-radius: 4px ;


}
</style>
