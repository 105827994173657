<script setup>

</script>

<template>
    <div  class="bg-green-600 text-white py-4 px-6 rounded text-xl mt-auto w-[90%] absolute bottom-4 text-center  border " style="background: linear-gradient(217.36deg, #FFFFFF 7.24%, #F4F5FF 85.25%);">
        <span class="font-bold" style="color: #1EB36B">Done </span>
        <svg class="inline" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 1.625C6.7275 1.625 1.625 6.7275 1.625 13C1.625 19.2725 6.7275 24.375 13 24.375C19.2725 24.375 24.375 19.2725 24.375 13C24.375 6.7275 19.2725 1.625 13 1.625ZM18.5819 10.0588L12.285 17.485C12.1469 17.6475 11.9437 17.7531 11.7325 17.7694H11.6675C11.4725 17.7694 11.2856 17.7044 11.1394 17.5744L7.5075 14.4625C7.17437 14.17 7.13375 13.65 7.42625 13.3169C7.71875 12.9756 8.23063 12.935 8.57188 13.2275L11.5781 15.8112L17.3387 9.0025C17.6313 8.66125 18.1431 8.62063 18.4844 8.91313C18.8256 9.1975 18.8663 9.7175 18.5819 10.0588Z" fill="url(#paint0_linear_155_38)"/>
            <defs>
                <linearGradient id="paint0_linear_155_38" x1="4.86307" y1="10.2292" x2="20.2503" y2="25.2461" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#1EB36B"/>
                    <stop offset="1" stop-color="#24E44E"/>
                </linearGradient>
            </defs>
        </svg>

    </div>
</template>

<style scoped>

</style>