// store.js
import { createStore } from 'vuex';
import Cookies from "js-cookie";

const store = createStore({
    state() {
        return {
            isTelegramAccountVerified: false,
            isTelegramChatSubscribed: false,
            isTwitterAnyRetweet: false,
            isTwitterFollowing: false,
            telegramFooDriverBotUserName: '',
            telegramFooDriverChatId: '',
            twitterFooDriverUserName: '',
            walletAddress: '',
            token: Cookies.get('token')
        };
    },
    mutations: {
        setFreshState(state, freshState) {
            state.isTelegramAccountVerified = freshState.isTelegramAccountVerified;
            state.isTelegramChatSubscribed = freshState.isTelegramChatSubscribed;
            state.isTwitterAnyRetweet = freshState.isTwitterAnyRetweet;
            state.isTwitterFollowing = freshState.isTwitterFollowing;
            state.telegramFooDriverBotUserName = freshState.telegramFooDriverBotUserName;
            state.telegramFooDriverChatId = freshState.telegramFooDriverChatId;
            state.twitterFooDriverUserName = freshState.twitterFooDriverUserName;
            state.walletAddress = freshState.walletAddress;
            state.token = freshState.token || Cookies.get('token');
        }
    },
    actions: {
        updateFreshState({ commit }, freshState) {
            commit('setFreshState', freshState);
        }
    },
    getters: {
        getFreshState(state) {
            return state;
        }
    }
});

export default store;
