<template>
    <button v-if="!getFreshState.token" @click="toggleModal"
            :class="{'bg-green-600 cursor-default': getFreshState.token, 'bg-red-600': !getFreshState.token }"
            class=" text-white py-4 px-6 rounded text-xl mt-auto w-[90%] absolute bottom-4">
        Download app
    </button>

    <DoneButton v-else/>

    <div id="modal_download_app" tabindex="-1" aria-hidden="true"
         class="p-2 hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
        <div class="relative p-4 w-full max-w-xl max-h-full p-2">
            <div class="bg-white rounded-lg p-6 w-full max-w-xl">
                <button class="absolute top-8 right-8 text-gray-500 hover:text-gray-800" @click="toggleModal">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                         class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
                    </svg>
                </button>
                <div class="text-center">
                    <h2 class="text-2xl font-semibold mb-4">Step 1</h2>
                    <h3 class="text-2xl font-bold text-red-600 mb-4 blue"><span class="text-red-600">Download</span> the
                        app to proceed to the next step</h3>
                    <div class="flex justify-around mb-6">
                        <div class="flex flex-col items-center">
                            <h3 class="font-bold mb-2 blue">Android</h3>

                            <img src="/img/google.png" alt="Android QR Code" class="w-32 h-32 mb-2 p-4 border">

                            <a href="https://play.google.com/store/apps/details?id=com.foodriver.general&pcampaignid=web_share"
                               target="_blank"
                               class="text-gray-400 text-lg underline font-normal">Download App</a>
                        </div>
                        <div class="flex flex-col items-center">
                            <h3 class="font-bold mb-2 blue">iOS</h3>
                            <img src="/img/ios.png" alt="iOS QR Code" class="w-32 h-32 mb-2 p-4 border">
                            <a href="https://apps.apple.com/app/foodriver-food-and-drinks/id6474551114" target="_blank"
                               class="text-gray-400 text-lg font-normal underline">Download App</a>
                        </div>
                    </div>

                    <h2 class="text-2xl font-semibold mb-4">Step 2</h2>

                    <div v-if="!smsSent" class="mb-2">

                        <h3 class="text-2xl font-bold mb-4 blue"><span class="text-red-600">Confirm</span> the phone
                            number you used during registration in the mobile app.</h3>
                        <MazPhoneNumberInput
                            show-code-on-list
                            :no-example="true"
                            :fetchCountry="true"
                            country-locale="en-EN"
                            @update="results = $event"
                        />
                        <div>
                            <button @click="sendSms" :disabled="!results.isValid"
                                    :class="{ 'bg-gray-600' : !results.isValid, 'bg-red-600 hover:bg-red-700': results.isValid}"
                                    class="mt-4 text-white rounded py-2 px-4 verify_button w-[80%]">Verify
                            </button>
                        </div>
                    </div>

                    <div v-if="smsSent">
                        <h3 class="text-xl font-bold text-red-600 mb-4">Enter code</h3>
                        <input type="text" v-model="smsCode" placeholder="Enter code"
                               class="border border-gray-300 rounded p-2 mb-4 w-[80%]">
                        <button @click="auth"
                                class="hover:bg-red-700 bg-red-600 text-white rounded py-2 px-4  verify_button w-[80%]">
                            Submit
                        </button>
                    </div>
                    <div v-if="message" :class="{'text-red-600': isError, 'text-green-600': !isError}" class="mt-4">
                        {{ message }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import {Modal} from "flowbite";
import Cookies from 'js-cookie';
import {freshState} from "../freshState";
import {mapGetters} from "vuex";
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'


export default {
    name: 'ModalComponent',
    computed: {
        ...mapGetters(['getFreshState']),
    },
    data() {
        return {
            modal: '',
            message: '',
            isError: false,
            smsSent: false,
            smsCode: '',
            results: '',
        };
    },
    components: {
        MazPhoneNumberInput
    },

    methods: {

        toggleModal() {
            if (!this.getFreshState.token) {
                this.modal.toggle();
            }
            if (this.getFreshState.token && this.modal.isVisible()) {
                this.modal.toggle();
            }
        },
        async sendSms() {
            // 7 + 917...
            let phone = this.results.countryCallingCode + this.results.nationalNumber;
            phone = phone.replace(/\+/g, '');
            this.phone = phone
            try {
                const response = await axios.post('https://mobile-foodriver.site/staging/api/v1/user/send_code', {
                    "targetAction": "Unknown",
                    "phone": phone,
                    "udid": "string"
                });
                this.smsSent = true;
                this.message = "Verification code sent successfully!";
                this.isError = false;
            } catch (error) {
                let errorMessage = "Failed to send verification code. Please try again.";
                if (error.response && error.response.data) {
                    let { errorMessage, allowResendingDate } = error.response.data;
                    const resendingDate = new Date(allowResendingDate * 1000);
                    const now = new Date();
                    const timeDiff = resendingDate - now;

                    if (timeDiff >= 0) {
                        const minutesLeft = Math.floor(timeDiff / 60000).toString().padStart(2, '0');
                        const secondsLeft = Math.floor((timeDiff % 60000) / 1000).toString().padStart(2, '0');

                        errorMessage = ` You can resend in ${minutesLeft}:${secondsLeft}.`;
                    }
                    this.message = errorMessage;
                } else if (error.message) {
                    this.message = errorMessage;
                }
                this.isError = true;
            }
        },
        async auth() {
            try {
                const response = await axios.post('https://mobile-foodriver.site/staging/api/v1/user/auth', {
                    "code": this.smsCode,
                    "countryCode": "string",
                    "osCulture": "string",
                    "phone": this.phone
                });
                const token = response.data.token;
                if (token) {
                    Cookies.set('token', token, {expires: 1});
                    window.token = token
                    this.getFreshState.token = token
                    await freshState();
                    this.toggleModal()

                    this.isError = false;
                } else {
                    this.message = "Failed to auth. Please try again.";
                    this.isError = true;
                }

            } catch (error) {
                console.log(error)
                this.message = "Failed to auth. Please try again.";
                this.isError = true;
            }
        },

    },
    mounted() {
        this.getFreshState.token = Cookies.get('token')
        const targetEl = document.getElementById('modal_download_app');
        const options = {
            placement: 'center',
            backdropClasses: 'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40',
        };
        this.modal = new Modal(targetEl, options);
    }
};
</script>

<style>


.v-field__field {
    border: 1px solid #E2E2E7 !important;

}

.v-field--appended {
    padding-inline-end: 0 !important;
}

.v-field__append-inner {
    display: none !important;
}

.v-field__overlay {
    background: linear-gradient(217.36deg, #FFFFFF 7.24%, #F4F5FF 85.25%) !important;

}

.m-input-label {
    font-size: 1rem;
    line-height: 1.5rem;
}
</style>